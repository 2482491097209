import React, { useState, createContext ,useContext, useEffect} from "react";
import * as vars from "../utils/vars"




// Create a new context
const defaultContext = {
        actionError:'',
        isLoggedIn:false,
        isAudioOpen: false,
        isMuted:false,
        selectedTopping:''  
};
export const AppContext = createContext(defaultContext);

// Create a context provider component
export const AppProvider = ({ children }) => {
    const [oauthUser, setOauthUser ] = useState(null)
    const [actionError, setActionError ] = useState(null)
    const [actionText, setActionText ] = useState(vars?.actionText['cust'])
    const [ geoData, setGeoData ] = useState(false)
    const [ appData, setAppData ] = useState(false)
    const [ userData, setUserData ] = useState()
    const [ isIntroOpen, setIsIntroOpen ] = useState(true)
    const [ isHelpOpen, setIsHelpOpen ] = useState(false)
    const [ isAudioOpen, setIsAudioOpen ] = useState(true)
    const [ isLeaderBoardOpen, setIsLeaderBoardOpen ] = useState(true)
    const [ isMuted, setIsMuted ] = useState(false)
    const [ appTheme, setAppTheme ] = useState({})
    const [ selectedTopping, setSelectedTopping ] = useState('')
    const [ customers, setCustomers ] = useState(null)
    const [ currentCustomer, setCurrentCustomer ] = useState()
    const [ orders, setOrders ] = useState([])
    const [ currentOrder, setCurrentOrder ] = useState([])
    const [ selectedSandwiches, setSelectedSandwiches ] = useState([])
    const [ allSandwiches, setAllSandwiches ] = useState([])
    const [ sandwichCount, setSandwichCount ] = useState(0)
    const [ sliceCue, setSliceCue ] = useState([])
    const [ cueHold , setCueHold ] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)
  

  const updateAppData = (newData) => {
    setAppData((prevState) => ({
        appTheme,
        userData,
        geoData,
        oauthUser,
        actionError,
        actionText,
        isIntroOpen, 
        isHelpOpen,
        isAudioOpen,
        isMuted, 
        selectedTopping,
         customers,
         currentCustomer,
          orders,
        currentOrder,
        sliceCue,
        cueHold,
        selectedSandwiches,
        sandwichCount,
        allSandwiches,
        isLeaderBoardOpen,
        ...userData,
        ...prevState,
        ...newData,   
    }));
    
  };
  

  return (
    <AppContext.Provider value={{ 
        appData, 
        updateAppData,
        appTheme, 
        setAppTheme,
        userData, 
        setUserData,
        geoData,
        setGeoData,
        actionError, 
        setActionError,
        actionText, 
        setActionText, 
        oauthUser, 
        setOauthUser, 
        isLoggedIn,
        setIsLoggedIn,
        isIntroOpen, 
        setIsIntroOpen,
        isHelpOpen, 
        setIsHelpOpen,
        isAudioOpen, 
        setIsAudioOpen,
        isMuted, 
        setIsMuted,
        selectedTopping, 
        setSelectedTopping,
        customers, 
        setCustomers, 
        currentCustomer, 
        setCurrentCustomer,
        orders,
        setOrders,
        currentOrder,
        setCurrentOrder,
        sliceCue, 
        setSliceCue,
        cueHold , 
        setCueHold,
        selectedSandwiches,
        setSelectedSandwiches,
        allSandwiches, 
        setAllSandwiches,
        sandwichCount, 
        setSandwichCount,
        isLeaderBoardOpen, 
        setIsLeaderBoardOpen
        }}>
      {children}
    </AppContext.Provider>
  );
};
export const AppState = () => {
  return useContext(AppContext);
};