import turkey_url  from  "../images/TurkeySlice.png" 
import ham_url  from  "../images/HamSlice2.png" 
import prosc_url  from  "../images/ProsciuttiniSlice.png" 
import capp_url  from  "../images/CappicolaSlice.png" 
import salami_url  from  "../images/SalamiSlice.png" 
import pepp_url  from  "../images/PepperoniSlice.png" 
import prov_url  from  "../images/ProvoloneSlice.png" 
import swiss_url  from  "../images/SwissSlice.png" 
import american_url  from  "../images/AmericanSlice.png" 


export const maxWidth = 400
export const maxHeight = 700
export const TitleFont = 'Jockey One'
export const MenuTitleFont = 'Fugaz One'
export const textFont = 'Futura'
export const cueMax = 8;
export const orderMax = 5
export const customerAmount = 15;
export const eyeText ='Feed Me...'
export const actionText = {
    game_paused:"*PAUSED* Close tab to continue.",
    cust:"⬆ click customer to collect order.",
    ord_taken:"↙ order taken click prep.",
    cue_prep:"↙ click prep order to add to cue.",
    cue_select:"⬇ select sandwich(es) to prepare.",
    cue_hold:"- sandwich(es) on hold",
    cue_hold_use:"-sandwich(es) being used",
    fridge_slecet:"⬇ select topping to slice.",
    cue_slice:"click slice to top selected sandwich(es). ⬇",
    cue_slicing:"Slicing!",
    err_noTop:'Select a topping to slice!',
    err_wrongLayer:'Sorry wrong topping!',
    err_noSan:'Select sandwich(es) to prepare!',
}
// export const outlierNum = 0

export const meats = [
    {'turkey': turkey_url },
    {'ham': ham_url},
    {'prosciuttini': prosc_url},
    {'cappicola': capp_url},
    {'salami': salami_url}, 
    {'pepperoni': pepp_url}]

export const cheeses = [
    {'provolone': prov_url},
    {'swiss': swiss_url},
    {'american': american_url}
]

export const sandwiches = [
    {'veggie':[cheeses[0],cheeses[1]]},
    {'turkey':[cheeses[0],meats[0]]},
    {'ham':[cheeses[0],meats[1]]},
    {'club':[cheeses[0],meats[1],meats[0]]},
    {'slamma':[cheeses[0],meats[1],meats[2],meats[3]]},
    {'italian':[cheeses[0],meats[1],meats[2],meats[3],meats[4],meats[5]]},
]